import React, { useEffect, useState } from 'react'
import { Flex, Heading, Text, Textarea, Input, Button } from '@theme-ui/components'
import ContactWidget from '../../../../../../gatsby-theme-minimal/src/components/General/ContactDetails'
import cloudinaryImageOptimizer from '../../../../../../gatsby-theme-minimal/src/helpers/cloudinaryImageOptimizer'

export default function ContactForm({
  businessData,
  configData,
  options,
  variantName,
  locationIndex,
  isMultiLocationSite
}) {
  const { name, street, city, state, zip, phone } = businessData
  const { containerStyles, title, subtitle, text, contactDetails, email, backgroundImages } = options

  const [cloudinaryImage, setCloudinaryImage] = useState(undefined)

  const formName = `${name}-${city}`

  const styles = containerStyles ? JSON.parse(containerStyles) : {}
  const variant = variantName ? variantName : 'contactFormV1'

  useEffect(() => {
    if (backgroundImages) {
      setCloudinaryImage(
        cloudinaryImageOptimizer(
          backgroundImages[locationIndex],
          window.innerWidth < 1800 ? window.innerWidth + 500 : window.innerWidth
        )
      )
    }
    return () => {}
  }, [])

  return (
    <Flex
      variant={`${variant}.container`}
      sx={{
        background: backgroundImages
          ? `linear-gradient( rgba(0,0,0,0.5),rgba(0,0,0,0.5) ), url('${cloudinaryImage ? cloudinaryImage : ''}')`
          : '',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        ...styles
      }}
    >
      {title && (
        <Heading as="h2" variant={`${variant}.title`} className="title">
          {title}
        </Heading>
      )}
      {subtitle && (
        <Heading as="h3" variant={`${variant}.subtitle`} className="subtitle">
          {subtitle}
        </Heading>
      )}
      {text && (
        <Text as="p" variant={`${variant}.text`} className="text">
          {text}
        </Text>
      )}

      {contactDetails && (
        <>
          <ContactWidget
            phone={phone}
            address={{ name, street, city, state, zip }}
            email={email}
            alternativeGoogleMapLink={configData.alternativeGoogleMapLink}
          />
          <br></br>
        </>
      )}

      <Flex
        as="form"
        variant={`${variantName}.formContainer`}
        name="contact" //  this can not be dyanmic form names and details must be static
        method="post"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
      >
        <Input type="hidden" name="form-name" value="contact" />
        <Input
          variant={`${variantName}.name`}
          name="name"
          type="text"
          id="name"
          mb={3}
          placeholder="Name"
          className="inputField name"
        />
        <Input
          variant={`${variantName}.email`}
          type="email"
          name="email"
          id="email"
          mb={3}
          placeholder="Email"
          className="inputField email"
        />
        <Input
          variant={`${variantName}.location`}
          type="text"
          name="location"
          id="location"
          mb={3}
          placeholder="Location"
          className="inputField location"
        />
        <Textarea
          name="message"
          id="message"
          variant={`${variantName}.message`}
          rows="6"
          mb={3}
          placeholder="Message"
          className="textField message"
        />
        {isMultiLocationSite ? (
          <Input sx={{ display: 'none !important' }} name="location" id="location" value={city} placeholder={city} />
        ) : (
          ''
        )}
        <Button className="button submitBtn">Submit</Button>
      </Flex>
    </Flex>
  )
}
